/* eslint-disable no-loop-func */
import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
// import ReactPrismEditor from "react-prism-editor";
import { knownFunctions } from '../../utils/const';
import Highlight, { defaultProps } from 'prism-react-renderer';
// import theme from 'prism-react-renderer/themes/vsDark';
import theme from '../../utils/syntax-highlight-theme';
import HomeScreenView from './HomeScreenView';
import { useDebounce } from '../../utils/useDebounce';
import functionsConfig from '../../functionsConfig';
import evenFunctionsConfig from '../../evenFunctions';
import unevenFunctionsConfig from '../../unevenFunctions';
import userDefinedFunctionsConfig from '../../userDefinedFunctions';
import * as XRegExp from 'xregexp';

const HomeScreenContainer = () => {
    const [code, setCode] = useState(
        `/* \nHello and welcome to ampscript.io\nStart typing or paste your script here to see the highlighted\ncode and warnings about potential syntax errors.\n\nPress ctrl + enter to beautify your AMPscript.\n\nThis application is still under development. Syntax validation\nwill detect problems with:\n- Opening and closing tags of AMPscript blocks and inline AMPscript\n- Syntax errors within FOR loops and IF/ELSE statements\n- Syntax errors within AMPscript function and setting variables\n\nLast updated on September 28th, 2024.\n*/\n\n%%[\nVAR @text\nSET @text = "Hello, world!"\nOutput(v(@text))\n]%%`,
    );
    const [textareaHeight, setTextareaHeight] = useState(window.innerHeight);
    const [textareaWidth, setTextareaWidth] = useState(
        window.innerWidth * 0.55,
    );
    const [warnings, setWarnings] = useState([]);
    const debouncedInputValue = useDebounce(code, 1000);

    const [variables, setVariables] = useState([]);
    const [functions, setFunctions] = useState([]);

    const allFunctionNamesLowerCase = knownFunctions.map((func) =>
        func.toLowerCase(),
    );

    useEffect(() => {
        const editorHeight =
            document.querySelector('.simple-editor').scrollHeight;
        const editorWidth =
            document.querySelector('.simple-editor').scrollWidth;
        setTextareaHeight(editorHeight);
        setTextareaWidth(editorWidth);
    }, [code]);

    const startAndEndsOfBlocks = (linesOfCode) => {
        const startsAndEnds = [];
        linesOfCode.forEach((line, index) => {
            const startOrEnd = line.match(/%%\[|\]%%/gi);
            if (startOrEnd) {
                startOrEnd.forEach((item) => {
                    if (item === '%%[') {
                        startsAndEnds.push({ type: 'start', line: index + 1 });
                    } else {
                        startsAndEnds.push({ type: 'end', line: index + 1 });
                    }
                });
            }
        });
        return startsAndEnds;
    };

    const startAndEndsOfInlineAmpscript = (linesOfCode) => {
        const startsAndEnds = [];
        linesOfCode.forEach((line, index) => {
            const startOrEnd = line.match(/%%\=|\=%%/gi);
            if (startOrEnd) {
                startOrEnd.forEach((item) => {
                    if (item === '%%=') {
                        startsAndEnds.push({ type: 'start', line: index + 1 });
                    } else {
                        startsAndEnds.push({ type: 'end', line: index + 1 });
                    }
                });
            }
        });
        return startsAndEnds;
    };

    const checkStartAndEndsInlineAmpscript = (startsAndEnds) => {
        // console.log('these are start and ends: ', startsAndEnds);
        let warningsAfterValidation = [];
        let previousBlock = 'start';
        startsAndEnds.forEach((blockOperator, index) => {
            //first block sign if end throw error
            if (!index && blockOperator.type === 'end') {
                warningsAfterValidation.push({
                    text: `Unstarted Inline AMPscript block in line ${blockOperator.line}`,
                    line: blockOperator.line,
                });
                previousBlock = 'end';
            } else if (index) {
                let changeBlock;
                if (index + 1 === startsAndEnds.length) {
                    if (blockOperator.type === 'start') {
                        warningsAfterValidation.push({
                            text: `Unclosed Inline AMPscript block in line ${blockOperator.line}`,
                            line: blockOperator.line,
                        });
                        if (previousBlock === 'start') {
                            warningsAfterValidation.push({
                                text: `Unclosed Inline AMPscript block in line ${
                                    startsAndEnds[index - 1].line
                                }`,
                                line: startsAndEnds[index - 1].line,
                            });
                        }
                    } else if (previousBlock === 'end') {
                        warningsAfterValidation.push({
                            text: `Unstarted Inline AMPscript block in line ${blockOperator.line}`,
                            line: blockOperator.line,
                        });
                    }
                } else if (previousBlock === 'start') {
                    if (blockOperator.type === 'start') {
                        warningsAfterValidation.push({
                            text: `Unclosed Inline AMPscript block in line ${
                                startsAndEnds[index - 1].line
                            }`,
                            line: startsAndEnds[index - 1].line,
                        });
                    } else {
                        changeBlock = true;
                    }
                } else if (previousBlock === 'end') {
                    if (blockOperator.type === 'end') {
                        warningsAfterValidation.push({
                            text: `Unstarted Inline AMPscript block in line ${blockOperator.line}`,
                            line: blockOperator.line,
                        });
                    } else {
                        changeBlock = true;
                    }
                }
                previousBlock === 'start'
                    ? changeBlock
                        ? (previousBlock = 'end')
                        : (previousBlock = 'start')
                    : changeBlock
                    ? (previousBlock = 'start')
                    : (previousBlock = 'end');
            }
        });
        if (startsAndEnds.length === 1 && previousBlock === 'start') {
            warningsAfterValidation.push({
                text: `Unclosed Inline AMPscript block in line ${startsAndEnds[0].line}`,
                line: startsAndEnds[0].line,
            });
        }
        return warningsAfterValidation;
    };

    const checkStartAndEnds = (startsAndEnds) => {
        // console.log(startsAndEnds)
        let warningsAfterValidation = [];
        let previousBlock = 'start';
        startsAndEnds.forEach((blockOperator, index) => {
            //first block sign if end throw error
            if (!index && blockOperator.type === 'end') {
                warningsAfterValidation.push({
                    text: `Unstarted AMPscript block in line ${blockOperator.line}`,
                    line: blockOperator.line,
                });
                previousBlock = 'end';
            } else if (index) {
                let changeBlock;
                if (index + 1 === startsAndEnds.length) {
                    if (blockOperator.type === 'start') {
                        warningsAfterValidation.push({
                            text: `Unclosed AMPscript block in line ${blockOperator.line}`,
                            line: blockOperator.line,
                        });
                        if (previousBlock === 'start') {
                            warningsAfterValidation.push({
                                text: `Unclosed AMPscript block in line ${
                                    startsAndEnds[index - 1].line
                                }`,
                                line: startsAndEnds[index - 1].line,
                            });
                        }
                    } else if (previousBlock === 'end') {
                        warningsAfterValidation.push({
                            text: `Unstarted AMPscript block in line ${blockOperator.line}`,
                            line: blockOperator.line,
                        });
                    }
                } else if (previousBlock === 'start') {
                    if (blockOperator.type === 'start') {
                        warningsAfterValidation.push({
                            text: `Unclosed AMPscript block in line ${
                                startsAndEnds[index - 1].line
                            }`,
                            line: startsAndEnds[index - 1].line,
                        });
                    } else {
                        changeBlock = true;
                    }
                } else if (previousBlock === 'end') {
                    if (blockOperator.type === 'end') {
                        warningsAfterValidation.push({
                            text: `Unstarted AMPscript block in line ${blockOperator.line}`,
                            line: blockOperator.line,
                        });
                    } else {
                        changeBlock = true;
                    }
                }
                previousBlock === 'start'
                    ? changeBlock
                        ? (previousBlock = 'end')
                        : (previousBlock = 'start')
                    : changeBlock
                    ? (previousBlock = 'start')
                    : (previousBlock = 'end');
            }
        });
        if (startsAndEnds.length === 1 && previousBlock === 'start') {
            warningsAfterValidation.push({
                text: `Unclosed AMPscript block in line ${startsAndEnds[0].line}`,
                line: startsAndEnds[0].line,
            });
        }
        return warningsAfterValidation;
    };

    const findFunctionsInCode = (linesOfCode, code) => {
        let warningsAfterValidation = [];
        const functions = [];
        const codesBeforeBrackets = code.split('(');
        let lines = 1;
        codesBeforeBrackets.forEach((textBeforeBracket) => {
            let textToCountLines = textBeforeBracket;
            lines += (textToCountLines.match(/\n/g) || []).length;
            const words = textBeforeBracket.split(/\n| |,|=/);
            const functionName = words[words.length - 1];
            // console.log('functions',functions)
            functions.push({ name: functionName, line: lines });
        });
        functions.pop();
        // console.log('these are functions: ', functions);

        setFunctions([
            ...new Set(
                functions
                    .map((func) => func.name)
                    .filter(
                        (func) =>
                            allFunctionNamesLowerCase.indexOf(
                                func.toLowerCase(),
                            ) > -1,
                    )
                    .sort((a, b) => a.localeCompare(b)),
            ),
        ]);

        functions
            .filter((func) => func.name)
            .forEach((funcName) => {
                let isInKnownFunctions = false;
                knownFunctions.forEach((knownFunction) => {
                    // console.log("To funkcja : ", funcName.name.toLowerCase() )
                    // console.log("This is known function: ", knownFunction.toLowerCase())
                    if (
                        funcName.name.toLowerCase() ===
                            knownFunction.toLowerCase() &&
                        !isInKnownFunctions
                    ) {
                        isInKnownFunctions = true;
                    }
                });
                if (!isInKnownFunctions) {
                    if (linesOfCode[funcName.line - 1]) {
                        warningsAfterValidation.push({
                            text: `Can't recognize function name "${funcName.name}" in line ${funcName.line}`,
                            line: funcName.line,
                        });
                    }
                }
            });

        try {
            let lineNumber = 1;
            const functionsAndParams = [];
            let functionsParametersArray = XRegExp.matchRecursive(
                code,
                '\\(',
                '\\)',
                'g',
                {
                    valueNames: ['between', 'left', 'match', 'right'],
                },
            );
            functionsParametersArray.forEach((param) => {
                if (param.name === 'between') {
                    lineNumber += param.value.split(/\n/).length - 1;
                    var lastWord = param.value.split(/\n| |,|=/);
                    functionsAndParams.push({
                        name: lastWord[lastWord.length - 1],
                        line: lineNumber,
                    });
                } else if (param.name === 'match') {
                    lineNumber += param.value.split(/\n/).length - 1;
                    functionsAndParams[functionsAndParams.length - 1].param =
                        param.value;
                }
            });
            functionsAndParams.pop(); //remove last unnececary

            let newElements = [];
            functionsAndParams.forEach((el) => {
                if (el.param) {
                    let functionsParametersArray = XRegExp.matchRecursive(
                        el.param,
                        '\\(',
                        '\\)',
                        'g',
                        {
                            valueNames: ['between', 'left', 'match', 'right'],
                        },
                    );
                    functionsParametersArray.forEach((param) => {
                        if (param.name === 'between') {
                            const lineNumber =
                                el.line + param.value.split(/\n/).length - 1;
                            var lastWord = param.value.split(/\n| |,|=/);
                            newElements.push({
                                name: lastWord[lastWord.length - 1],
                                line: lineNumber,
                            });
                        } else if (param.name === 'match') {
                            lineNumber += param.value.split(/\n/).length - 1;
                            newElements[newElements.length - 1].param =
                                param.value;
                        }
                    });
                }
            });
            let newElements1 = [];
            newElements.forEach((el) => {
                if (el.param) {
                    let functionsParametersArray = XRegExp.matchRecursive(
                        el.param,
                        '\\(',
                        '\\)',
                        'g',
                        {
                            valueNames: ['between', 'left', 'match', 'right'],
                        },
                    );
                    functionsParametersArray.forEach((param) => {
                        if (param.name === 'between') {
                            const lineNumber =
                                el.line + param.value.split(/\n/).length - 1;
                            var lastWord = param.value.split(/\n| |,|=/);
                            newElements1.push({
                                name: lastWord[lastWord.length - 1],
                                line: lineNumber,
                            });
                        } else if (param.name === 'match') {
                            lineNumber += param.value.split(/\n/).length - 1;
                            newElements1[newElements1.length - 1].param =
                                param.value;
                        }
                    });
                }
            });
            let newElements2 = [];
            newElements1.forEach((el) => {
                if (el.param) {
                    let functionsParametersArray = XRegExp.matchRecursive(
                        el.param,
                        '\\(',
                        '\\)',
                        'g',
                        {
                            valueNames: ['between', 'left', 'match', 'right'],
                        },
                    );
                    functionsParametersArray.forEach((param) => {
                        if (param.name === 'between') {
                            const lineNumber =
                                el.line + param.value.split(/\n/).length - 1;
                            var lastWord = param.value.split(/\n| |,|=/);
                            newElements2.push({
                                name: lastWord[lastWord.length - 1],
                                line: lineNumber,
                            });
                        } else if (param.name === 'match') {
                            lineNumber += param.value.split(/\n/).length - 1;
                            newElements2[newElements2.length - 1].param =
                                param.value;
                        }
                    });
                }
            });
            let newElements3 = [];
            newElements2.forEach((el) => {
                if (el.param) {
                    let functionsParametersArray = XRegExp.matchRecursive(
                        el.param,
                        '\\(',
                        '\\)',
                        'g',
                        {
                            valueNames: ['between', 'left', 'match', 'right'],
                        },
                    );
                    functionsParametersArray.forEach((param) => {
                        if (param.name === 'between') {
                            const lineNumber =
                                el.line + param.value.split(/\n/).length - 1;
                            var lastWord = param.value.split(/\n| |,|=/);
                            newElements3.push({
                                name: lastWord[lastWord.length - 1],
                                line: lineNumber,
                            });
                        } else if (param.name === 'match') {
                            lineNumber += param.value.split(/\n/).length - 1;
                            newElements3[newElements3.length - 1].param =
                                param.value;
                        }
                    });
                }
            });
            let newElements4 = [];
            newElements3.forEach((el) => {
                if (el.param) {
                    let functionsParametersArray = XRegExp.matchRecursive(
                        el.param,
                        '\\(',
                        '\\)',
                        'g',
                        {
                            valueNames: ['between', 'left', 'match', 'right'],
                        },
                    );
                    functionsParametersArray.forEach((param) => {
                        if (param.name === 'between') {
                            const lineNumber =
                                el.line + param.value.split(/\n/).length - 1;
                            var lastWord = param.value.split(/\n| |,|=/);
                            newElements4.push({
                                name: lastWord[lastWord.length - 1],
                                line: lineNumber,
                            });
                        } else if (param.name === 'match') {
                            lineNumber += param.value.split(/\n/).length - 1;
                            newElements4[newElements4.length - 1].param =
                                param.value;
                        }
                    });
                }
            });

            let mergedRecursiveFunctionsWithParams = functionsAndParams
                .concat(
                    newElements,
                    newElements1,
                    newElements2,
                    newElements3,
                    newElements4,
                )
                .flat()
                .filter((el) => el.param !== undefined);

            mergedRecursiveFunctionsWithParams.forEach((func) => {
                func.param = func.param.replace(/ *\([^)]*\) */g, '');
            });
            mergedRecursiveFunctionsWithParams.forEach((func) => {
                let numberOfParams = 0;
                if (func.param) {
                    const commas = (func.param.match(/,/g) || []).length;
                    numberOfParams = commas + 1;
                }
                const funcConfig = functionsConfig.find(
                    (functionConfig) =>
                        functionConfig.functionName.toLowerCase() ===
                        func.name.toLowerCase(),
                );
                if (funcConfig) {
                    const minFunctionArgs = funcConfig.args.filter(
                        (arg) => arg.required,
                    ).length;
                    const maxFunctionArgs = funcConfig.args.length;
                    if (
                        numberOfParams < minFunctionArgs ||
                        numberOfParams > maxFunctionArgs
                    ) {
                        if (funcConfig.args.length) {
                            warningsAfterValidation.push({
                                type: 'functionParams',
                                link: funcConfig.link,
                                name: funcConfig.functionName,
                                text: `function in line ${func.line}. The ${funcConfig.functionName} function works with the following arguments:`,
                                line: func.line,
                                args: funcConfig.args,
                            });
                        } else {
                            warningsAfterValidation.push({
                                type: 'functionParams',
                                link: funcConfig.link,
                                name: funcConfig.functionName,
                                text: `function in line ${func.line}. The ${funcConfig.functionName} function function does not accept any arguments.`,
                                line: func.line,
                            });
                        }
                    }
                }
                const evenFunctionConfig = evenFunctionsConfig.find(
                    (functionConfig) =>
                        functionConfig.functionName.toLowerCase() ===
                        func.name.toLowerCase(),
                );
                if (evenFunctionConfig) {
                    const minFunctionArgs = evenFunctionConfig.args.filter(
                        (arg) => arg.required,
                    ).length;
                    if (numberOfParams < minFunctionArgs) {
                        warningsAfterValidation.push({
                            type: 'functionParams',
                            link: evenFunctionConfig.link,
                            name: evenFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${evenFunctionConfig.functionName} function works with the following arguments:`,
                            line: func.line,
                            args: evenFunctionConfig.args,
                        });
                    } else if (numberOfParams % 2 !== 0) {
                        warningsAfterValidation.push({
                            type: 'functionParams',
                            link: evenFunctionConfig.link,
                            name: evenFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${evenFunctionConfig.functionName} function should have even number of arguments`,
                            line: func.line,
                            args: evenFunctionConfig.args,
                        });
                    }
                }
                const unevenFunctionConfig = unevenFunctionsConfig.find(
                    (functionConfig) =>
                        functionConfig.functionName.toLowerCase() ===
                        func.name.toLowerCase(),
                );
                if (unevenFunctionConfig) {
                    const minFunctionArgs = unevenFunctionConfig.args.filter(
                        (arg) => arg.required,
                    ).length;
                    if (numberOfParams < minFunctionArgs) {
                        warningsAfterValidation.push({
                            type: 'functionParams',
                            link: unevenFunctionConfig.link,
                            name: unevenFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${unevenFunctionConfig.functionName} function works with the following arguments:`,
                            line: func.line,
                            args: unevenFunctionConfig.args,
                        });
                    } else if (numberOfParams % 2 !== 1) {
                        warningsAfterValidation.push({
                            type: 'functionParams',
                            link: unevenFunctionConfig.link,
                            name: unevenFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${unevenFunctionConfig.functionName} function should have odd number of arguments`,
                            line: func.line,
                            args: unevenFunctionConfig.args,
                        });
                    }
                }
                const userDefinedFunctionConfig =
                    userDefinedFunctionsConfig.find(
                        (functionConfig) =>
                            functionConfig.functionName.toLowerCase() ===
                            func.name.toLowerCase(),
                    );
                if (userDefinedFunctionConfig) {
                    const minFunctionArgs =
                        userDefinedFunctionConfig.args.filter(
                            (arg) => arg.required,
                        ).length;
                    const numberOfRequiredPairParams =
                        Number(func.param.split(',')[1]) * 2 + 2;
                    if (numberOfParams < minFunctionArgs) {
                        warningsAfterValidation.push({
                            type: 'functionParams',
                            link: userDefinedFunctionConfig.link,
                            name: userDefinedFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${userDefinedFunctionConfig.functionName} function works with the following arguments:`,
                            line: func.line,
                            args: userDefinedFunctionConfig.args,
                        });
                    } else if (
                        !numberOfRequiredPairParams ||
                        numberOfRequiredPairParams < 4
                    ) {
                        warningsAfterValidation.push({
                            type: 'functionParamsUserDefined',
                            link: userDefinedFunctionConfig.link,
                            name: userDefinedFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${userDefinedFunctionConfig.functionName} function second argument should be a number greater than 0.`,
                            line: func.line,
                            args: userDefinedFunctionConfig.args,
                        });
                    } else if (numberOfParams !== numberOfRequiredPairParams) {
                        warningsAfterValidation.push({
                            type: 'functionParamsUserDefined',
                            link: userDefinedFunctionConfig.link,
                            name: userDefinedFunctionConfig.functionName,
                            text: `function in line ${func.line}. The ${userDefinedFunctionConfig.functionName} function's second argument defines that it should pass ${numberOfRequiredPairParams} arguments.`,
                            line: func.line,
                            args: userDefinedFunctionConfig.args,
                        });
                    }
                }
            });
            // console.log('final: ', mergedRecursiveFunctionsWithParams);
        } catch (e) {
            if (
                e.message.includes('Unbalanced right delimiter found') ||
                e.message.includes('Unbalanced left delimiter found')
            ) {
                const words = e.message.split(' ');
                const errorCharacter = Number(words[words.length - 1]);
                let lineNum = 0;
                let lineLastChar = 0;
                let errorLine = 0;
                const codeLines = code.split('\n');
                codeLines.forEach((line) => {
                    lineNum++;
                    lineLastChar += line.length + 1;
                    if (errorLine === 0 && lineLastChar > errorCharacter) {
                        errorLine = lineNum;
                    }
                });
                warningsAfterValidation.push({
                    text: `Unmatched parantheses in line ${errorLine}`,
                    line: errorLine,
                });
            }
        }

        return warningsAfterValidation;
    };

    const findForLoops = (linesOfCode) => {
        let forLoopKeywordsArray = [];
        linesOfCode.forEach((line, index) => {
            let kewordRegex =
                /(%%\[|]%%|(^|\s)for(\s|$)|(^|\s)to(\s|$)|(^|\s)downto(\s|$)|(^|\s)do(\s|$)|(^|\s)next(\s|$)|"|')/gi;
            let keyword;
            let inApostrophe = false;
            do {
                keyword = kewordRegex.exec(line);
                if (keyword) {
                    if (keyword[0] === `"` || keyword[0] === `'`) {
                        inApostrophe = !inApostrophe;
                    } else {
                        if (!inApostrophe) {
                            forLoopKeywordsArray.push({
                                line: index + 1,
                                type: keyword[0],
                                character: keyword.index,
                            });
                        }
                    }
                }
            } while (keyword);
        });
        return forLoopKeywordsArray;
    };

    const removeForKeywordsOutsideBlock = (forKeywords) => {
        let outsideBlock = true;
        let forLoopKeywordsArray = forKeywords;
        forLoopKeywordsArray.forEach((line, index) => {
            // console.log('line.type', line.type, outsideBlock)
            if (line.type === '%%[') {
                outsideBlock = false;
                //  console.log('false', outsideBlock)
            }
            if (line.type === ']%%') {
                outsideBlock = true;
                //  console.log('true', outsideBlock)
            }
            if (outsideBlock && line.type !== ']%%' && line.type !== '%%[') {
                //  console.log('delete', index, outsideBlock, line.type)
                forLoopKeywordsArray[index] = { type: '', line: 0 };
            }
        });
        const keywordsArrayWithoutSpaces = forLoopKeywordsArray.map(
            (keyword) => {
                return {
                    ...keyword,
                    type: keyword.type.replace(/\s/g, ''),
                };
            },
        );
        return keywordsArrayWithoutSpaces.filter((el) => el.type !== '');
    };
    const findForWarningsInCode = (linesOfCode, startsAndEnds) => {
        const forLoopKeywordsArray = findForLoops(linesOfCode);
        const forLoopKeywordsInsideBlock =
            removeForKeywordsOutsideBlock(forLoopKeywordsArray);
        let warningsAfterValidation = validateForLoopKeywords(
            forLoopKeywordsInsideBlock,
        );
        return warningsAfterValidation;
    };

    const findIfLoopsInCode = (linesOfCode, startsAndEnds) => {
        const forLoopKeywordsArray = [];
        let outsideBlock = true;
        // console.log('linesOfCode', linesOfCode)
        linesOfCode.forEach((line, index) => {
            const keyword = line.match(
                /(%%\[|]%%|\bif\b|\bthen\b|\bendif\b|\belseif\b|\belse\b|"|')/gi,
            );
            if (keyword) {
                let inApostrophe = false;
                keyword.forEach((item) => {
                    if (item === `"` || item === `'`) {
                        inApostrophe = !inApostrophe;
                    } else {
                        if (!inApostrophe) {
                            forLoopKeywordsArray.push({
                                type: item,
                                line: index + 1,
                            });
                        }
                    }
                });
            }
        });
        // console.log(forLoopKeywordsArray);
        forLoopKeywordsArray.forEach((line, index) => {
            //    console.log('line.type', line.type, outsideBlock)
            if (line.type === '%%[') {
                outsideBlock = false;
                // console.log('false', outsideBlock)
            }
            if (line.type === ']%%') {
                outsideBlock = true;
                // console.log('true', outsideBlock)
            }
            if (outsideBlock && line.type !== ']%%' && line.type !== '%%[') {
                // console.log('delete', index, outsideBlock, line.type)
                forLoopKeywordsArray[index] = { type: '', line: 0 };
            }
        });
        //    console.log('forLoopKeywordsArray2',forLoopKeywordsArray)
        let warningsAfterValidation = validateIfLoopKeywords(
            forLoopKeywordsArray.filter((el) => el.type !== ''),
        );
        return warningsAfterValidation;
    };

    const validateIfLoopKeywords = (loopKeywordsArray) => {
        let warningsAfterValidation = [];
        // console.log(loopKeywordsArray);
        let closeLoopArray = [];
        let elsesArray = [];
        let elsesInLoop = [];
        loopKeywordsArray.forEach((keyword, i) => {
            // console.log('item: ', keyword);
            //then always after if/elseif
            if (
                (keyword.type.toLowerCase() === 'if' ||
                    keyword.type.toLowerCase() === 'elseif') &&
                loopKeywordsArray[i + 1]?.type.toLowerCase() !== 'then' &&
                loopKeywordsArray[i + 1]?.type.toLowerCase() !== 'if'
            ) {
                warningsAfterValidation.push({
                    text: `"Then" keyword in conditional statement is missing ${
                        loopKeywordsArray[i].type.toLowerCase() ===
                        loopKeywordsArray[i + 2]?.type.toLowerCase()
                            ? ''
                            : `in line ${keyword.line}`
                    }`,
                    line: keyword.line,
                });
            }
            // Old if else outside block checking
            // if (loopKeywordsArray[i].type.toLowerCase() === 'if' &&
            // loopKeywordsArray[i - 1]?.type.toLowerCase() !== '%%[' && loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'then' && loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'if') {
            //     warningsAfterValidation.push({
            //         text: `A conditional statement outside of an AMPscript block in line ${keyword.line}`,
            //         line: keyword.line,
            //     });
            // }
            //all if's with endif
            // console.log(loopKeywordsArray)
            if (loopKeywordsArray[i].type.toLowerCase() === 'if') {
                closeLoopArray.push(loopKeywordsArray[i]);
                elsesInLoop.push(0);
            } else if (loopKeywordsArray[i].type.toLowerCase() === 'endif') {
                if (closeLoopArray.length > 0) {
                    closeLoopArray.pop();
                    elsesArray.push({
                        number: elsesInLoop.pop(),
                        line: keyword.line,
                    });
                } else {
                    warningsAfterValidation.push({
                        text: `"If" keyword in conditional statement is missing  ${
                            loopKeywordsArray[i].type.toLowerCase() ===
                            loopKeywordsArray[i + 2]?.type.toLowerCase()
                                ? ''
                                : `in line ${keyword.line}`
                        }`,
                        line: keyword.line,
                    });
                }
            }
            if (
                loopKeywordsArray[i].type.toLowerCase() === 'then' &&
                loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'if' &&
                loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'elseif'
            ) {
                warningsAfterValidation.push({
                    text: `"If"/"Elseif" keyword in conditional statement is missing  ${
                        loopKeywordsArray[i].type.toLowerCase() ===
                        loopKeywordsArray[i + 2]?.type.toLowerCase()
                            ? ''
                            : `in line ${keyword.line}`
                    }`,
                    line: keyword.line,
                });
            }
            if (loopKeywordsArray[i].type.toLowerCase() === 'else') {
                elsesInLoop[elsesInLoop.length - 1]++;
            }
        });
        // console.log('closeLoopArray',closeLoopArray)
        // ${loopKeywordsArray[i].type.toLowerCase() === loopKeywordsArray[i + 2].type.toLowerCase() ? '' : `in line ${ifElement.line}`}`
        closeLoopArray.forEach((ifElement, i) =>
            warningsAfterValidation.push({
                text: `"Endif" keyword in conditional statement is missing ${
                    ifElement[i]?.type.toLowerCase() ===
                    ifElement[i + 1]?.type.toLowerCase()
                        ? ''
                        : `in line ${ifElement.line}`
                }`,
                line: ifElement.line,
            }),
        );
        // console.log('this is elses arr: ', elsesArray);
        elsesArray.forEach((elseElements, i) => {
            if (elseElements.number > 1) {
                warningsAfterValidation.push({
                    text: `Only one "else" keyword is allowed in conditional statement ${
                        elseElements[i]?.type.toLowerCase() ===
                        elseElements[i + 1]?.type.toLowerCase()
                            ? ''
                            : `in line ${elseElements.line}`
                    }`,
                    line: elseElements.line,
                }); //in ${ifElement.line}`, line: ifElement.line })
            }
        });
        return warningsAfterValidation;
    };

    const validateForLoopKeywords = (loopKeywordsArray) => {
        let warningsAfterValidation = [];
        let nextValidationArray = [];
        let forArray = [];
        let nextArray = [];
        // console.log('loopKeywordsArray',loopKeywordsArray)
        loopKeywordsArray.forEach((keyword, i) => {
            // console.log(loopKeywordsArray[i].type.toLowerCase())
            // Old style loop outside block validation
            // if (loopKeywordsArray[i].type.toLowerCase() === 'for' &&
            // loopKeywordsArray[i - 1]?.type.toLowerCase() !== '%%[' && loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'do' && loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'for') {
            //     warningsAfterValidation.push({
            //         text: `A for loop outside of an AMPscript block in line ${keyword.line}`,
            //         line: keyword.line,
            //     });
            // }
            if (loopKeywordsArray[i].type.toLowerCase() === 'do') {
                nextValidationArray.push(loopKeywordsArray[i]);
            } else if (loopKeywordsArray[i].type.toLowerCase() === 'next') {
                nextValidationArray.pop();
                nextArray.push(loopKeywordsArray[i]);
            }
            if (loopKeywordsArray[i].type.toLowerCase() === 'for') {
                forArray.push(loopKeywordsArray[i]);
            }
            if (
                loopKeywordsArray[i].type.toLowerCase() === 'to' &&
                loopKeywordsArray[i - 1]?.type.toLowerCase() !== 'for'
            ) {
                warningsAfterValidation.push({
                    text: `"For" keyword in loop is missing ${
                        loopKeywordsArray[i].type.toLowerCase() ===
                        loopKeywordsArray[i + 2]?.type.toLowerCase()
                            ? ''
                            : `in line ${keyword.line}`
                    }`,
                    line: keyword.line,
                });
            } else if (
                loopKeywordsArray[i].type.toLowerCase() === 'for' &&
                loopKeywordsArray[i + 1]?.type.toLowerCase() !== 'to' &&
                loopKeywordsArray[i + 1]?.type.toLowerCase() !== 'downto'
            ) {
                warningsAfterValidation.push({
                    text: `"To"/"downto" keyword in loop is missing ${
                        loopKeywordsArray[i].type.toLowerCase() ===
                        loopKeywordsArray[i + 2]?.type.toLowerCase()
                            ? ''
                            : `in line ${keyword.line}`
                    }`,
                    line: keyword.line,
                });
            } else if (
                loopKeywordsArray[i].type.toLowerCase() === 'to' &&
                loopKeywordsArray[i + 1]?.type.toLowerCase() !== 'do' &&
                loopKeywordsArray[i + 1]?.type.toLowerCase() !== 'downto'
            ) {
                warningsAfterValidation.push({
                    text: `"Do" keyword in loop is missing ${
                        loopKeywordsArray[i].type.toLowerCase() ===
                        loopKeywordsArray[i + 2]?.type.toLowerCase()
                            ? ''
                            : `in line ${keyword.line}`
                    }`,
                    line: keyword.line,
                });
            }
        });
        nextValidationArray.forEach((doElement, i) =>
            warningsAfterValidation.push({
                text: `Next keyword in loop is missing ${
                    doElement[i]?.type.toLowerCase() ===
                    doElement[i + 1]?.type.toLowerCase()
                        ? ''
                        : `in line ${doElement.line}`
                }`,
                line: doElement.line,
            }),
        );
        forArray.forEach((forElement) => {
            nextArray.shift();
        });
        nextArray.forEach((nextElement, i) =>
            warningsAfterValidation.push({
                text: `For keyword in loop is missing ${
                    nextElement[i]?.type.toLowerCase() ===
                    nextElement[i + 1]?.type.toLowerCase()
                        ? ''
                        : `in line ${nextElement.line}`
                }`,
                line: nextElement.line,
            }),
        );
        return warningsAfterValidation;
    };

    const excludeComments = (code) => {
        const regexStartMultiComm = /\/\*/gi;
        const regexEndMultiComm = /\*\//gi;
        let startOfComments = [];
        let endOfComments = [];
        let result;
        const regexStartBlock = /%%\[/gi;
        const regexEndBlock = /]%%/gi;
        let startOfBlock = [];
        let endOfBlock = [];
        while ((result = regexStartMultiComm.exec(code))) {
            //console.log('resultStart',result)
            startOfComments.push(result.index);
        }
        while ((result = regexEndMultiComm.exec(code))) {
            // console.log('resultEnd',result)
            endOfComments.push(result.index);
        }
        while ((result = regexStartBlock.exec(code))) {
            //console.log('resultStart',result)
            startOfBlock.push(result.index);
        }
        while ((result = regexEndBlock.exec(code))) {
            // console.log('resultEnd',result)
            endOfBlock.push(result.index);
        }

        const commentBlocks = createCommentsBlocks(
            startOfComments,
            endOfComments,
        );
        // const blocks = createCommentsBlocks(
        //     startOfBlock,
        //     endOfBlock,
        // );
        // console.log("comments: ", commentBlocks)
        // console.log("blocks: ", blocks)
        const linesOfCode = code.split('\n');
        let linesWithoutComments = code.split('\n');
        commentBlocks.forEach((block) => {
            let sumOfChars = 0;
            let previousSumOfChars = 0;
            linesOfCode.forEach((line, index) => {
                sumOfChars += line.length + 1;

                if (block.start < sumOfChars) {
                    if (block.end + 3 >= sumOfChars) {
                        // console.log("sum of chars: ", sumOfChars,line)
                        // console.log("previous sum of chars: ", previousSumOfChars)
                        if (previousSumOfChars > block.start) {
                            linesWithoutComments[index] = '';
                        } else {
                            const changedLine = line.slice(
                                0,
                                block.start - previousSumOfChars,
                            );
                            linesWithoutComments[index] = changedLine;
                        }
                    }
                }
                previousSumOfChars = sumOfChars;
            });
        });
        for (const property in linesWithoutComments) {
            linesWithoutComments[property] = linesWithoutComments[
                property
            ].replace(
                /(\/\*[\w\'\s\r\n\*]*\*\/)|(\/\/[\w\s\']*)|(\<![\-\-\s\w\>\/]*\>)/gi,
                '',
            );
        }

        return linesWithoutComments;
    };

    const excludeQuotesFromLines = (linesWithoutComments) => {

        for (const property in linesWithoutComments) {

            linesWithoutComments[property] = linesWithoutComments[

                property

            ].replace(/(".*")|('.*')/gi, "''");

        }

        return linesWithoutComments;

    };



    const excludeQuotesFromCode = (code) =>

        code.replace(/(".*")|('.*')/gi, "''");

    const createCommentsBlocks = (startArray, endArray) => {
        let blocks = [];
        let startIndex,
            endIndex = -5;
        startArray.forEach((el, i) => {
            if (el > endIndex + 1) {
                startIndex = el;
                endIndex = endArray.find((element) => element > startIndex);
                blocks.push({
                    start: startIndex,
                    end: endIndex,
                });
            }
        });
        return blocks;
    };

    const findStatementsAndLoopOutsideBlock = (codeToValidate) => {
        let warningsAfterValidation = [];
        const outsideBlockElements = codeToValidate
            .replace(/%%\[[\s\S]*?]%%/g, '')
            .match(/\bif @\b|\bif not empty\b|\bif empty\b|\bfor @\b/gi);
        outsideBlockElements?.length > 0 &&
            outsideBlockElements.forEach((el) =>
                warningsAfterValidation.push({
                    text: `A ${
                        el.toLowerCase() === 'for @'
                            ? 'for loop'
                            : 'conditional statement'
                    } outside of an AMPscript block`,
                    line: 0,
                }),
            );
        return warningsAfterValidation;
    };

    const findSetStatementsOutsideBlock = (codeToValidate) => {
        let warningsAfterValidation = [];
        const outsideBlockElements = codeToValidate
            .replace(/%%\[[\s\S]*?]%%/g, '')
            .match(/\bset @\b|\bvar @\b/gi);
        outsideBlockElements?.length > 0 &&
            outsideBlockElements.forEach((el) =>
                warningsAfterValidation.push({
                    text: `${
                        el.toLowerCase() === 'set @'
                            ? 'Set statement'
                            : 'Variable declaration'
                    } outside of an AMPscript block`,
                    line: 0,
                }),
            );
        return warningsAfterValidation;
    };

    const findNotSetVariables = (codeToValidate) => {
        let warningsAfterValidation = [];
        let variablesObject = {};
        const forLoopKeywordsArray = findForLoops(codeToValidate);
        const forLoopKeywordsInsideBlock = removeForKeywordsOutsideBlock(
            forLoopKeywordsArray,
        ).filter(
            (el) =>
                el.type.toLowerCase() === 'for' ||
                el.type.toLowerCase() === 'to' ||
                el.type.toLowerCase() === 'next' ||
                el.type.toLowerCase() === 'downto',
        );
        codeToValidate.forEach((line, i) => {
            let lineToDeclaration = line;
            let lineToVariable = line;
            let lineToAlocation = line;
            let alocationRegex = /\bvar\s{1,}@[a-zA-Z0-9_]+\b/gi;
            let declarationRegex = /\bset\s{1,}@[a-zA-Z0-9_]+\b/gi;
            let variableRegex = /@[a-zA-Z0-9_]+\b/gi;
            let declaration, variable, alocation;

            alocation = alocationRegex.exec(lineToAlocation);

            if (!alocation) {
                do {
                    declaration = declarationRegex.exec(lineToDeclaration);
                    if (declaration) {
                        const currentDeclaration = declaration[0]
                            .substring(4)
                            .toLowerCase();
                        if (!variablesObject[currentDeclaration]) {
                            variablesObject[currentDeclaration] = [];
                        }
                        variablesObject[currentDeclaration] = [
                            ...variablesObject[currentDeclaration],
                            {
                                lineNumber: i + 1,
                                character: declaration.index + 4,
                                type: 'DECLARATION',
                            },
                        ];
                    }
                } while (declaration);
                do {
                    variable = variableRegex.exec(lineToVariable);
                    if (variable) {
                        const currentVariable = variable[0].toLowerCase();
                        if (!variablesObject[currentVariable]) {
                            variablesObject[currentVariable] = [];
                        }
                        if (
                            !variablesObject[currentVariable].find(
                                (el) =>
                                    el.lineNumber === i + 1 &&
                                    el.character === variable.index,
                            )
                        ) {
                            variablesObject[currentVariable] = [
                                ...variablesObject[currentVariable],
                                {
                                    lineNumber: i + 1,
                                    character: variable.index,
                                    type: 'USAGE',
                                },
                            ];
                        }
                    }
                } while (variable);
            }
        });
        let forLoopDepth = 0;
        let forLoops = [];

        forLoopKeywordsInsideBlock.forEach((keyword) => {
            if (keyword.type.toLowerCase() === 'for') {
                forLoops.push({
                    startCounterLine: keyword.line,
                    startCounterCharacter: keyword.character,
                    depth: 0,
                });
                forLoopDepth++;
            } else if (keyword.type.toLowerCase() === 'to' || keyword.type.toLowerCase() === 'downto') {
                forLoops[forLoops.length - 1] = {
                    ...forLoops[forLoops.length - 1],
                    endCounterLine: keyword.line,
                    endCounterCharacter: keyword.character,
                };
            } else {
                const loopIndex = forLoops.map((el) => el.depth).indexOf(0);
                if (loopIndex > -1) {
                    forLoops[loopIndex] = {
                        ...forLoops[loopIndex],
                        depth: forLoopDepth,
                    };
                } else {
                    forLoops[forLoops.length - 1] = {
                        ...forLoops[forLoops.length - 1],
                        depth: forLoopDepth,
                    };
                }
                forLoopDepth--;
            }
        });
        forLoops.forEach((loop) => {
            const nexts = forLoopKeywordsInsideBlock.filter(
                (keyword) =>
                    keyword.type.toLowerCase() === 'next' &&
                    (keyword.line > loop.endCounterLine ||
                        (keyword.line === loop.endCounterLine &&
                            keyword.character > loop.endCounterCharacter)),
            );
            loop.endOfLoopLine = nexts[loop.depth - 1]?.line;
            loop.endOfLoopCharacter = nexts[loop.depth - 1]?.character;
        });

        for (const [key, variableHistory] of Object.entries(variablesObject)) {
            let isDeclared = false;
            variableHistory.forEach((historyObject) => {
                if (historyObject.type === 'DECLARATION') {
                    isDeclared = true;
                }
                if (historyObject.type === 'USAGE' && !isDeclared) {
                    forLoops.forEach((loop) => {
                        if (
                            loop.startCounterLine < historyObject.lineNumber ||
                            (loop.startCounterLine ===
                                historyObject.lineNumber &&
                                loop.startCounterCharacter <
                                    historyObject.character)
                        ) {
                            if (
                                loop.endCounterLine >
                                    historyObject.lineNumber ||
                                (loop.endCounterLine ===
                                    historyObject.lineNumber &&
                                    loop.endCounterCharacter >
                                        historyObject.character)
                            ) {
                                if (!loop.counter) {
                                    loop.counter = key;
                                }
                            }
                        }
                    });
                }
            });
        }
        for (const [key, variableHistory] of Object.entries(variablesObject)) {
            let isDeclared = false;
            variableHistory.forEach((historyObject) => {
                if (historyObject.type === 'DECLARATION') {
                    isDeclared = true;
                }
                if (historyObject.type === 'USAGE' && !isDeclared) {
                    const forLoopsArr = forLoops.filter(
                        (loop) => loop.counter === key,
                    );
                    if (forLoopsArr.length > 0) {
                        let isInArr = false;
                        forLoopsArr.forEach((loop) => {
                            if (
                                loop.startCounterLine <
                                    historyObject.lineNumber ||
                                (loop.startCounterLine ===
                                    historyObject.lineNumber &&
                                    loop.startCounterCharacter <
                                        historyObject.character)
                            ) {
                                isInArr = true;
                            }
                        });
                        if (!isInArr) {
                            warningsAfterValidation.push({
                                text: `Variable ${key} has not been set`,
                                line: historyObject.lineNumber,
                            });
                        }
                    } else {
                        warningsAfterValidation.push({
                            text: `Variable ${key} has not been set`,
                            line: historyObject.lineNumber,
                        });
                    }
                }
            });
        }

        const uniqueWarnings = Array.from(
            new Set(warningsAfterValidation.map((a) => a.text)),
        ).map((text) => {
            return warningsAfterValidation.find((a) => a.text === text);
        });

        
        const variables = [];

        for (const key of Object.keys(variablesObject)) {

            // console.log(key, variablesObject[key]);

            if (

                variablesObject[key].findIndex(

                    (el) => el.type === 'DECLARATION',

                ) > -1

            ) {

                variables.push(key);

            }

        }

        // console.log('variablesObject', variablesObject, variables, warnings);

        setVariables(variables.sort((a, b) => a.localeCompare(b)));



        return uniqueWarnings;

    };

    
    const validateCode = (codeToValidate) => {
        const linesWithoutComments = excludeComments(codeToValidate);
        // console.log('linesWithoutComments',linesWithoutComments)
        const startsAndEnds = startAndEndsOfBlocks(linesWithoutComments);
        const warningsStartEnd = checkStartAndEnds(startsAndEnds);
        const inlineAmpscriptBlocks =
            startAndEndsOfInlineAmpscript(linesWithoutComments);
        const warningsInlineAmpscriptStartEnd =
            checkStartAndEndsInlineAmpscript(inlineAmpscriptBlocks);
        const warningsFunctionNames = findFunctionsInCode(
            linesWithoutComments,
            codeToValidate,
        );
        const warningsForLoops = findForWarningsInCode(
            linesWithoutComments,
            startsAndEnds,
        );
        const warningsIfLoops = findIfLoopsInCode(
            linesWithoutComments,
            startsAndEnds,
        );
        // const warningsOutsideBlock = findOutsideBlockInCode(codeToValidate);
        const warningsStatementsAndLoopsOutsideBlock =
            findStatementsAndLoopOutsideBlock(codeToValidate);
        const warningsSetStatementsOutsideBlock =
            findSetStatementsOutsideBlock(codeToValidate);

        const warningsNotSetVariables =
            findNotSetVariables(linesWithoutComments);

        const sortedWarningsByLines = [
            ...warningsStartEnd,
            ...warningsInlineAmpscriptStartEnd,
            ...warningsFunctionNames,
            ...warningsForLoops,
            ...warningsIfLoops,
            // ...warningsOutsideBlock
            ...warningsStatementsAndLoopsOutsideBlock,
            ...warningsSetStatementsOutsideBlock,
            ...warningsNotSetVariables,
        ].sort((a, b) => {
            if (a.line > b.line) {
                return 1;
            }
            if (a.line < b.line) {
                return -1;
            }
            return 0;
        });
        setWarnings(sortedWarningsByLines);
    };

    useEffect(() => {
        setWarnings([]);
        validateCode(debouncedInputValue);
    }, [debouncedInputValue]);

    const styles = {
        root: {
            boxSizing: 'border-box',
            fontFamily: '"Dank Mono", "Fira Code", monospace',
            ...theme.plain,
        },
    };

    const highlight = (code) => (
        <Highlight
            {...defaultProps}
            theme={theme}
            code={code}
            language="javascript"
        >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                // <Fragment className={className} style={style}>
                <>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            <LineNo>{i + 1}</LineNo>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </>
                // </Fragment>
            )}
        </Highlight>
    );

    return (
        <HomeScreenView
            warnings={warnings}
            code={code}
            setCode={setCode}
            highlight={highlight}
            styles={styles}
            textareaHeight={textareaHeight}
            textareaWidth={textareaWidth}
            functions={functions}
            variables={variables}
        />
    );
};

export default HomeScreenContainer;

export const LineNo = styled.span`
    display: inline-block;
    width: 2em;
    user-select: none;
    opacity: 0.3;
    position: relative;
`;