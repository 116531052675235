
export default function formatAMPscript(code) {
    const AMPscriptKnownFunctions = [
        'Add', 'AddMSCRMListMember', 'AddObjectArrayItem', 'AttachFile', 'AttributeValue',
        'AuthenticatedEmployeeID', 'AuthenticatedEmployeeNotificationAddress',
        'AuthenticatedEmployeeUserName', 'AuthenticatedEnterpriseID', 'AuthenticatedMemberID',
        'AuthenticatedMemberName', 'BarcodeURL', 'Base64Decode', 'Base64Encode', 'BeginImpressionRegion',
        'BuildOptionList', 'BuildRowSetFromJSON', 'BuildRowSetFromString', 'BuildRowSetFromXML', 'Char', 'ClaimRow', 'ClaimRowValue',
        'CloudPagesURL', 'Concat', 'ContentArea', 'ContentAreaByName', 'ContentBlockByID', 'ContentBlockByKey',
        'ContentBlockByName', 'ContentImageByID', 'ContentImageByKey', 'CreateMSCRMRecord', 'CreateObject',
        'CreateSalesforceObject', 'CreateSmsConversation', 'DataExtensionRowCount', 'DateAdd', 'DateDiff',
        'DateParse', 'DatePart', 'DecryptSymmetric', 'DeleteData', 'DeleteDE', 'DescribeMSCRMEntities',
        'DescribeMSCRMEntityAttributes', 'Divide', 'Domain', 'Empty', 'EncryptSymmetric', 'EndImpressionRegion',
        'EndSmsConversation', 'ExecuteFilter', 'ExecuteFilterOrderedRows', 'Field', 'Format', 'FormatCurrency',
        'FormatDate', 'FormatNumber', 'GetJWT', 'GetJWTByKeyName', 'GetPortfolioItem', 'GetPublishedSocialContent', 'GetSendTime',
        'GetSocialPublishURL', 'GetSocialPublishURLByName', 'GetValue', 'GUID', 'HTTPGet', 'HTTPPost2', 'HTTPPost',
        'HTTPRequestHeader', 'IIf', 'Image', 'IndexOf', 'InsertData', 'InsertDE', 'InvokeCreate', 'InvokeDelete',
        'InvokeExecute', 'InvokePerform', 'InvokeRetrieve', 'InvokeUpdate', 'IsCHTMLBrowser', 'IsEmailAddress',
        'IsNull', 'IsNullDefault', 'IsPhoneNumber', 'Length', 'LiveContentMicrositeURL', 'LocalDateToSystemDate',
        'LongSFID', 'Lookup', 'LookupOrderedRows', 'LookupOrderedRowsCS', 'LookupRows', 'LookupRowsCS',
        'Lowercase', 'MD5', 'MicrositeURL', 'Mod', 'Multiply', 'Now', 'Output', 'OutputLine', 'ProperCase', 'QueryParameter',
        'RaiseError', 'Random', 'RatingStars', 'Redirect', 'RedirectTo', 'RegExMatch', 'Replace', 'ReplaceList',
        'RequestParameter', 'RetrieveMSCRMRecords', 'RetrieveMSCRMRecordsFetchXML',
        'RetrieveSalesforceJobSources', 'RetrieveSalesforceObjects', 'Row', 'RowCount', 'SetObjectProperty',
        'SetSmsConversationNextKeyword', 'SetStateMSCRMRecord', 'SetValue', 'SHA1', 'SHA256', 'SHA512',
        'StringToDate', 'StringToHex', 'Substring', 'Subtract', 'SystemDateToLocalDate', 'TransformXML',
        'TreatAsContent', 'TreatAsContentArea', 'Trim', 'UpdateData', 'UpdateDE', 'UpdateMSCRMRecords',
        'UpdateSingleSalesforceObject', 'Uppercase', 'UpsertContact', 'UpsertData', 'UpsertDE',
        'UpsertMSCRMRecord', 'URLEncode', 'V', 'WAT', 'WATP', 'WrapLongURL', 'v', 'Write', 'Stringify', 'Platform.Load', 'catch', '}catch', 'try', '}try'
    ];

    const AMPscriptPersonalizationStrings = [
        '__AdditionalEmailAttribute1', '__AdditionalEmailAttribute2',
        '__AdditionalEmailAttribute3', '__AdditionalEmailAttribute4', '__AdditionalEmailAttribute5',
        '_DataSourceName', '_emailid', '_ImpressionRegionID', '_ImpressionRegionName', '_IsTestSend',
        '_JobSubscriberBatchID', '_listname', '_messagecontext', '_MessageTypePreference', '_messagetypepreference',
        '_PreHeader', '_replycontent', '_subscriberkey', 'AdditionalInfo_', 'comment', 'comment_', 'double_opt_in_url',
        'emailaddr', 'emailname_', 'firstname', 'firstname_', 'ftaf_url', 'fullname', 'fullname_', 'jobid', 'lastname',
        'lastname_', 'linkname', 'list_', 'listid', 'listsubid', 'member_addr', 'member_busname', 'member_city',
        'member_country', 'member_postalcode', 'member_state', 'memberid', 'microsite_base_url', 'mobile_number',
        'profile_center_url', 'replyemailaddress', 'replyname', 'short_code', 'subscriberid', 'subscription_center_url',
        'unsub_center_url', 'view_email_url', 'xtday', 'xtdayofweek', 'xtlongdate', 'xtmonth', 'xtmonthnumeric', 'xtshortdate', 'xtyear'
    ];

    const AMPscripKeywords = [
        'var', 'set', 'if', 'not', 'then', 'elseif', 'endif', 'iif',
        'for', 'to', 'downto', 'do', 'next'
    ];

    const AMPscriptLookup = {};
    AMPscriptKnownFunctions.forEach(func => AMPscriptLookup[func.toLowerCase()] = func);
    AMPscriptPersonalizationStrings.forEach(str => AMPscriptLookup[str.toLowerCase()] = str);

    console.clear();

    // Function to ensure block openings, closings, and 'set' are on separate lines
function formatBlockKeywords(code) {
    code = code.replace(/%%\[/g, '\n\n%%[\n\n').replace(/\]%%/g, '\n\n]%%\n\n');
    code = code.replace(/\/\*/g, '\n\n/*').replace(/\*\//g, '*/\n\n');

    // Ensure 'set' is always on its own line
    //code = code.replace(/(?<!\n)\s*(set\s+)/gi, '\n$1');  // Adds a new line before 'set' if not already on one
    
    // Ensure other keywords like 'if', 'var', etc., are on separate lines
    // this adds newline breaks also inside comments
    //code = code.replace(/(^|\s)(?<![@\w])(var|if|elseif|for|next|else|endif)(?![\w@])(\s|$)/gi, '\n$&');

    code = code.replace(/(\/\*[\s\S]*?\*\/|^.*$)/gm, (match) => {
        // Check if it's a comment (starting with '/*' or ending with '*/')
        if (match.trim().startsWith('/*') && match.trim().endsWith('*/')) {
            return match; // Return the comment unchanged
        }
    
        // Otherwise, process the line and ensure keywords are on new lines
        return match.replace(/(^|\s)(?<![@\w])(var|if|elseif|for|next|else|endif|set)(?![\w@])(\s|$)/gi, '\n$&');
    });
    
    
    return code;
}


    // Function to handle block comments and line comments
    function handleComments(lines) {
        let formattedLines = [];
        let insideBlockComment = false;
        let insideLineComment = false;

        lines.forEach(line => {
            let trimmedLine = line.trim();

            // Check for block comment start
            if (trimmedLine.startsWith('/*')) {
                insideBlockComment = true;
            }

            // If inside a block comment, add the line as is and check for the block comment end
            if (insideBlockComment) {
                formattedLines.push(line);
                if (trimmedLine.endsWith('*/')) {
                    insideBlockComment = false;
                }
                return;
            }

            // Check for line comment
            if (trimmedLine.startsWith('//')) {
                insideLineComment = true;
            }

            // If inside a line comment, add the line as is
            if (insideLineComment) {
                formattedLines.push(line);
                insideLineComment = false; // Line comments end at the end of the line
                return;
            }

            formattedLines.push(line);
        });

        return formattedLines;
    }   

// Function to replace known functions with correct casing and add a newline before 'Output' or 'SetObjectProperty'
function replaceKnownFunctions(lines, AMPscriptLookup) {
    return lines.map(line => {
        return line.replace(/([a-z_]+)/ig, (match) => {
            let lowerCaseMatch = match.toLowerCase();
            let formattedFunction = AMPscriptLookup[lowerCaseMatch];
            
            // Check if the function is known and part of AMPscriptKnownFunctions
            if (formattedFunction && AMPscriptKnownFunctions.includes(formattedFunction)) {
                
                // Add a newline before 'Output' or 'SetObjectProperty'
                if (formattedFunction === 'Output' || formattedFunction === 'SetObjectProperty' || formattedFunction === 'AddObjectArrayItem') {
                    return '\n' + formattedFunction;
                }

                return formattedFunction;
            }

            return match;
        });
    });
}


// Function to replace known personalization strings with correct casing
function replaceKnownStrings(lines, AMPscriptLookup) {
    return lines.map(line => {
        return line.replace(/([a-z_]+)/ig, (match) => {
            let lowerCaseMatch = match.toLowerCase();
            if (AMPscriptLookup[lowerCaseMatch] && AMPscriptPersonalizationStrings.includes(AMPscriptLookup[lowerCaseMatch])) {
                return AMPscriptLookup[lowerCaseMatch];
            }
            return match;
        });
    });
}

    function replaceKeywords(lines, keywords) {
        const keywordRegex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi'); // Regex for matching standalone keywords
        
        //console.log("Initial lines:", lines);  // Log the initial lines
    
        return lines.map(line => {
            //console.log("Original line:", line);  // Log each original line before processing
            
            const newLine = line.replace(/(\/\*[\s\S]*?\*\/|^.*$)/gm, (match) => {
                // Check if it's a block comment (starts with '/*' and ends with '*/')
                if (match.trim().startsWith('/*') && match.trim().endsWith('*/')) {
                    return match; // Return the comment unchanged
                }
    
                // Otherwise, apply keyword replacement to non-comment lines
                return match.replace(keywordRegex, (keyword) => {
                    //console.log("Matched keyword:", keyword);  // Log each matched keyword
                    return keyword.toUpperCase(); // Convert matched keywords to uppercase
                });
            });
    
            //console.log("Modified line:", newLine);  // Log each modified line after processing
            return newLine;  // Return the modified line
        });
    }
    

// Function to handle indentation
function handleIndentation(lines) {
    let formattedLines = [];
    let indentLevel = 0;

    lines.forEach(line => {
        let trimmedLine = line.trim();

        // Ensure newlines before and after block-related keywords if they are standalone
        let replacedLine = trimmedLine.replace(/(\/\*[\s\S]*?\*\/|^.*$)/gm, (match) => {
            // Check if it's a block comment (starts with '/*' and ends with '*/')
            if (match.trim().startsWith('/*') && match.trim().endsWith('*/')) {
                return match; // Return the comment unchanged
            }
        
            // Process non-comment lines and apply your keyword regex replacements
            return match
                .replace(/(^|\s)(?<![@\w])(else|endif)(?![\w@])(\s|$)/gi, '$1\n$2\n$3')
                .replace(/(^|\s)(?<![@\w])(if|elseif)(?![\w@])(\s|$)/gi, '$1$2$3') // No newline break for "if" and "elseif"
                .replace(/(^|\s)(?<![@\w])then(?![\w@])(\s|$)/gi, ' THEN\n$2')       // Ensure space before "then"
                .replace(/(^|\s)(?<![@\w])else(?![\w@])(\s|$)/gi, '$1\nELSE\n$2');
        });
        

        // Split into multiple lines if necessary
        let subLines = replacedLine.split('\n');

        subLines.forEach(subLine => {
            let subTrimmedLine = subLine.trim();

            // Handle block closings and decrease indent level before adding the line
            if (subTrimmedLine.match(/^(endif|else|elseif|next\b)/i)) {
                indentLevel = Math.max(0, indentLevel - 1);
                //console.log('Decreased indent for: ' + subTrimmedLine + ' | New indent level: ' + indentLevel);
            }

            // Add indentations
            if (subTrimmedLine) {
                formattedLines.push('    '.repeat(indentLevel) + subTrimmedLine);
                //console.log('Added line: ' + subTrimmedLine + ' | Current indent level: ' + indentLevel);
            }

            // Handle block openings and increase indent level immediately after adding the line
            if (subTrimmedLine.match(/^(if|for\s+.*\s+do|else)$/i)) {  // Include 'else' to increase indent
                indentLevel += 1;
                //console.log('Increased indent for: ' + subTrimmedLine + ' | New indent level: ' + indentLevel);
            }

            // Increase indent level for the line following a "then" keyword
            if (subTrimmedLine.match(/then$/i)) {
                indentLevel += 1;
                //console.log('Increased indent after then for: ' + subTrimmedLine + ' | New indent level: ' + indentLevel);
            }
        });

    });


    return formattedLines;
}

function formatHTML(lines) {
    return lines.map(line => {
        // Replace simple HTML tags with properly formatted ones
        line = line.replace(/<([a-z0-9]+)([^>]*)>/gi, (match, tagName, attributes) => {
            return `<${tagName.toLowerCase()}${attributes}>`; // Ensure tag name is lowercase
        });

        // Self-closing tags (e.g., <br>, <img>)
        line = line.replace(/<([a-z0-9]+)([^>]*)\/>/gi, (match, tagName, attributes) => {
            return `<${tagName.toLowerCase()}${attributes} />`; // Properly format self-closing tags
        });

        // Add a newline before closing tags for readability
        line = line.replace(/<\/([a-z0-9]+)>/gi, (match, tagName) => {
            return `\n</${tagName.toLowerCase()}>`; // Ensure closing tag is on a new line and in lowercase
        });

        // Ensure there's a space between tag attributes (e.g., <div class="abc"id="xyz"> -> <div class="abc" id="xyz">)
        line = line.replace(/([a-z0-9]+=)(["'])([^"']*)(["'])([a-z])/gi, '$1$2$3$4 $5');

        return line;
    });
}


    // Step 0: Remove all current formatting (newlines and indentations)
    code = code.replace(/\s+/g, ' '); // Replace all spaces and newlines with a single space

    // Step 1: Format block keywords
    code = formatBlockKeywords(code);

    // Split the code into lines
    let lines = code.split('\n');

    // Step 2: Handle comments
    lines = handleComments(lines);

     // Step 3: Replace known functions and personalization strings
     lines = replaceKnownFunctions(lines, AMPscriptLookup); // Replace functions first
     lines = replaceKnownStrings(lines, AMPscriptLookup); // Then replace personalization strings
 

    // Step 4: Handle indentation
    lines = handleIndentation(lines);

    // Step 5: Replace keywords with uppercase keywords
    lines = replaceKeywords(lines, AMPscripKeywords);

    // Step 6: Format HTML
    lines = formatHTML(lines);

    // Join the lines back together
    let formattedCode = lines.join('\n');

    // Add a double newline at the end of the script
    formattedCode += '\n\n\n\n\n';

    return formattedCode;
}
