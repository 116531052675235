import React from 'react';
import AppRouter from "./features/AppRouter/AppRouter";


const App = () => {
  return (
    <>
    <AppRouter/>
    </>
  );
}

export default App;