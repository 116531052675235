export default [
    {
      "functionName": "ClaimRow",
      "link": "https://ampscript.guide/claimrow",
      "description": "This function returns a single row from a Data Extension and reserves the values to prevent them from being used by another operation. This function is primarily used for assigning unique coupon codes to an audience at send time and recording other pertinent data from the send context.",
      "args":[{
        "name":"Data Extension from which to retrieve rows",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies a row as claimed",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the Data Extension column to populate",
        "type":"string",
        "required": false
      },{
        "name" : "Value of the Data Extension column to populate",
        "type":"string",
        "required": false
      },{
        "name" : "Additional name of the Data Extension column to populate",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value of the Data Extension column to populate",
        "type":"string",
        "required": false
      }],
      "evenArgsRequired": true
    },
    {
      "functionName": "GetSocialPublishURL",
      "link": "https://ampscript.guide/getsocialpublishurl",
      "description": "This function returns HTML for sharing a predefined region of content using the social network code found in the table below. When a recipient clicks on a social link defined by this function, they are directed to the social network. The default content to be shared is the Marketing Cloud landing page that contains the content defined in the predefined content region.",
      "args":[{
        "name":"Code that represents the social network",
        "type":"number",
        "required": true
      },{
        "name" : "Name of the predefined content region to share on the social network",
        "type":"string",
        "required": true
      },{
        "name" : "Optional parameter name specific to the selected social network",
        "type":"string",
        "required": false
      },{
        "name" : "Optional parameter value specific to the selected social network",
        "type":"string",
        "required": false
      }],
      "evenArgsRequired": true
    },
    {
      "functionName": "GetSocialPublishURLByName",
      "link": "https://ampscript.guide/getsocialpublishurlbyname",
      "description": "This function returns HTML for sharing a predefined region of content using a social network name found in the table below. When a recipient clicks on a social link defined by this function, they are directed to the social network and the default content to be shared is the Marketing Cloud landing page that contains the content defined in the predefined content region.",
      "args":[{
        "name":"Name of the social network",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the predefined content region to share on the social network",
        "type":"string",
        "required": true
      },{
        "name" : "Optional parameter name specific to the selected social network",
        "type":"string",
        "required": false
      },{
        "name" : "Optional parameter value specific to the selected social network",
        "type":"string",
        "required": false
      }],
      "evenArgsRequired": true
    },
    {
      "functionName": "Lookup",
      "link": "https://ampscript.guide/lookup",
      "description": "This function returns the value of a single column from a Data Extension.",
      "args":[{
        "name":"Name of the Data Extension from which to return a value",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column from which to return a value",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the row to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the row to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the row to retrieve",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the row to retrieve",
        "type":"string",
        "required": false
      }],
      "evenArgsRequired": true
    },
    {
      "functionName": "UpdateData",
      "link": "https://ampscript.guide/updatedata",
      "description": "This function updates the Data Extension row referenced in the column and value arguments. Values updated are specified with column name and value pairs. It returns the number of rows updated by the operation.",
      "args":[{
        "name":"Name of the Data Extension containing the rows to update",
        "type":"string",
        "required": true
      },{
        "name" : "Number of column and value pairs in the subsequent selection arguments",
        "type":"number",
        "required": true
      },{
        "name" : "Column name used for selecting rows to update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value for selecting rows to update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name used for selecting rows to update",
        "type":"string",
        "required": false
      },{
        "name" : "Additional column value for selecting rows to update",
        "type":"string",
        "required": false
      },{
        "name" : "Column name to update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value to update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name to update",
        "type":"string",
        "required": false
    },{
        "name" : "Additional column value to update",
        "type":"string",
        "required": false
     }],
      "evenArgsRequired": true
    },
    {
      "functionName": "UpdateDE",
      "link": "https://ampscript.guide/updatede",
      "description": "This function updates the Data Extension row referenced in the column and value arguments. Values updated are specified with column name and value pairs. This function does not return a value.",
      "args":[{
        "name":"Name of the Data Extension containing the rows to update",
        "type":"string",
        "required": true
      },{
        "name" : "Number of column and value pairs in the subsequent selection arguments",
        "type":"number",
        "required": true
      },{
        "name" : "Column name used for selecting rows to update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value for selecting rows to update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name used for selecting rows to update",
        "type":"string",
        "required": false
      },{
        "name" : "Additional column value for selecting rows to update",
        "type":"string",
        "required": false
      },{
        "name" : "Column name to update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value to update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name to update",
        "type":"string",
        "required": false
    },{
        "name" : "Additional column value to update",
        "type":"string",
        "required": false
    }],
      "evenArgsRequired": true
    },
    {
      "functionName": "UpdateSingleSalesforceObject",
      "link": "https://ampscript.guide/updatesinglesalesforceobject",
      "description": "This function updates a record in a Sales or Service Cloud standard or custom object. The function returns 1 if the record is updated successfully or 0if it fails.",
      "args":[{
        "name":"API name of the Salesforce object",
        "type":"string",
        "required": true
      },{
        "name" : "Record identifier to update",
        "type":"string",
        "required": true
      },{
        "name" : "API field name to update",
        "type":"string",
        "required": true
      },{
        "name" : "Value to update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional API field name to update",
        "type":"STRING",
        "required": false
      },{
        "name" : "Additional value to update",
        "type":"string",
        "required": false
      }],
      "evenArgsRequired": true
    },
    {
      "functionName": "UpsertData",
      "link": "https://ampscript.guide/upsertdata",
      "description": "This function adds or updates the Data Extension row referenced in the column and value arguments. Values updated are specified with column name and value pairs. If no record matches the values specified, a new row is added. It returns the number of rows added or updated by the operation.",
      "args":[{
        "name":"Name of the Data Extension containing the rows to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Number of column and value pairs in the proceeding selection arguments",
        "type":"number",
        "required": true
      },{
        "name" : "Column name used for selecting rows to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value for selecting rows to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name used for selecting rows to add or update",
        "type":"string",
        "required": false
      },{
        "name" : "Additional column value for selecting rows to add or update",
        "type":"string",
        "required": false
      },{
        "name" : "Column name to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name to add or update",
        "type":"string",
        "required": false
    },{
        "name" : "Additional column value to add or update",
        "type":"string",
        "required": false
    }],
      "evenArgsRequired": true
    },
    {
      "functionName": "UpsertDE",
      "link": "https://ampscript.guide/upsertde",
      "description": "This function adds or updates the Data Extension row referenced in the column and value arguments. Values updated are specified with column name and value pairs. If no record matches the values specified, a new row is added. This function does not return a value.",
      "args":[{
        "name":"Name of the Data Extension containing the rows to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Number of column and value pairs in the subsequent selection arguments",
        "type":"number",
        "required": true
      },{
        "name" : "Column name used for selecting rows to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value for selecting rows to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name used for selecting rows to add or update",
        "type":"string",
        "required": false
      },{
        "name" : "Additional column value for selecting rows to add or update",
        "type":"string",
        "required": false
      },{
        "name" : "Column name to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Column value to add or update",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name to add or update",
        "type":"string",
        "required": false
    },{
        "name" : "Additional column value to add or update",
        "type":"string",
        "required": false
    }],
      "evenArgsRequired": true
    }
    ]