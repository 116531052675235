export default [
    {
      "functionName": "BuildOptionList",
      "link": "https://ampscript.guide/buildoptionlist",
      "description": "This function outputs <option> elements for use inside <select>, <optgroup> or <datalist> HTML elements.",
      "args":[{
        "name":"Default option value",
        "type":"string",
        "required": true
      },{
        "name" : "Option value",
        "type":"string",
        "required": true
      },{
        "name" : "Option name",
        "type":"string",
        "required": true
      },{
        "name" : "Additional option value",
        "type":"string",
        "required": true
      },{
        "name" : "Additional option name",
        "type":"string",
        "required": true
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "ClaimRowValue",
      "link": "https://ampscript.guide/claimrowvalue",
      "description": "Like the ClaimRow function, ClaimRowValue returns a single row from a Data Extension and reserves the values to prevent them from being used by another operation.",
      "args":[{
        "name":"Data Extension from which to retrieve rows",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column from which to retrieve the value",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies a row as claimed",
        "type":"string",
        "required": true
      },{
        "name" : "Default value to use if there are no available rows to claim",
        "type":"string",
        "required": false
      },{
        "name" : "Name of the Data Extension column to populate",
        "type":"string",
        "required": false
      },{
        "name" : "Value of the Data Extension column to populate",
        "type":"string",
        "required": false
      },{
        "name" : "Additional name of the Data Extension column to populate",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value of the Data Extension column to populate",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "CloudPagesURL",
      "link": "https://ampscript.guide/cloudpagesurl",
      "description": "This function provides a convenient method of linking to landing pages (created in CloudPages) from email messages.",
      "args":[{
        "name":"Page identifier of the CloudPage landing page, available from Page Properties in the CloudPages Collection",
        "type":"number",
        "required": true
      },{
        "name" : "Parameter name included in encrypted query string",
        "type":"string",
        "required": false
      },{
        "name" : "Parameter value included in encrypted query string",
        "type":"string",
        "required": false
      },{
        "name" : "Additional parameter name",
        "type":"string",
        "required": false
      },{
        "name" : "Additional parameter value",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "DeleteData",
      "link": "https://ampscript.guide/deletedata",
      "description": "This function deletes rows in a Data Extension that exactly match the specified column name and value pairs. It returns the number of rows deleted by the operation.",
      "args":[{
        "name":"Data Extension from which to delete rows",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the row to delete",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the row to delete",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the row to delete",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the row to delete",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "DeleteDE",
      "link": "https://ampscript.guide/deletede",
      "description": "This function deletes rows in a Data Extension that exactly match the specified column name and value pairs. No output results from the use of this function.",
      "args":[{
        "name":"Data Extension from which to delete rows",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the row to delete",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the row to delete",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the row to delete",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the row to delete",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "InsertData",
      "link": "https://ampscript.guide/insertdata",
      "description": "This function inserts a row in a Data Extension with the specified column name and value pairs. It returns the number of rows inserted by the operation.",
      "args":[{
        "name":"Name of the Data Extension receiving the new row",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column receiving the value",
        "type":"string",
        "required": true
      },{
        "name" : "Value to insert into the column",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name receiving the value",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value to insert into the column",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "InsertDE",
      "link": "https://ampscript.guide/insertde",
      "description": "This function inserts a row in a Data Extension with the specified column name and value pairs. No output is returned from this function.",
      "args":[{
        "name":"Name of the Data Extension receiving the new row",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column receiving the value",
        "type":"string",
        "required": true
      },{
        "name" : "Value to insert into the column",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name receiving the value",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value to insert into the column",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "LookupOrderedRows",
      "link": "https://ampscript.guide/lookuporderedrows",
      "description": "This function returns a set of ordered rows from a Data Extension.",
      "args":[{
        "name":"Name of the Data Extension from which to return rows",
        "type":"string",
        "required": true
      },{
        "name" : "Number of rows to return. Specifying 0 or -1 returns all matching rows. The maximum number of rows returned is 2,000.",
        "type":"number",
        "required": true
      },{
        "name" : "Order-by clause that determines the order of the rows returned. Include a suffix of ASC (default) or DESC to specify the sort order of the rows. Specify multiple sort columns by separating them with a comma.",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the rows to retrieve",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the rows to retrieve",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "LookupOrderedRowsCS",
      "link": "https://ampscript.guide/lookuporderedrowscs",
      "description": "This function returns a set of ordered rows from a Data Extension. This variation of the LookupOrderedRows function treats selection values as case-sensitive.",
      "args":[{
        "name":"Name of the Data Extension from which to return rows",
        "type":"string",
        "required": true
      },{
        "name" : "Number of rows to return. Specifying 0 or -1 returns all matching rows. The maximum number of rows returned is 2,000.",
        "type":"number",
        "required": true
      },{
        "name" : "Comma-separated list of fields that determine the order of the rows returned. Optionally, each column can be followed by ASC (default) or DESC to specify the sort order. Specify multiple sort columns by separating them with a comma.",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the rows to retrieve",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the rows to retrieve",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "LookupRows",
      "link": "https://ampscript.guide/lookuprows",
      "description": "This function returns a set of unordered rows from a Data Extension.",
      "args":[{
        "name":"Name of the Data Extension from which to return rows",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the rows to retrieve",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the rows to retrieve",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "LookupRowsCS",
      "link": "https://ampscript.guide/lookuprowscs",
      "description": "This function returns a set of unordered rows from a Data Extension. This variation of the LookupRows function treats selection values as case-sensitive.",
      "args":[{
        "name":"Name of the Data Extension from which to return rows",
        "type":"string",
        "required": true
      },{
        "name" : "Name of the column that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Value that identifies the rows to retrieve",
        "type":"string",
        "required": true
      },{
        "name" : "Additional column name that identifies the rows to retrieve",
        "type":"string",
        "required": false
      },{
        "name" : "Additional value that identifies the rows to retrieve",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "MicrositeURL",
      "link": "https://ampscript.guide/micrositeurl",
      "description": "This function is similar to CloudPagesURL, but it’s specifically for linking to microsite pages created in Classic Content. When an email is sent that includes a link to a microsite page with this function, a URL link is generated to the page and is appended with an encrypted query string.",
      "args":[{
        "name":"Page ID of the microsite page, available from the Properties page",
        "type":"number",
        "required": true
      },{
        "name" : "Parameter name included in encrypted query string",
        "type":"string",
        "required": false
      },{
        "name" : "Parameter value included in encrypted query string",
        "type":"string",
        "required": false
      },{
        "name" : "Additional parameter name",
        "type":"string",
        "required": false
      },{
        "name" : "Additional parameter value",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "GetJWT",
      "link": "https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/GetJWT.html",
      "description": "Returns a JSON web token (JWT) including a hash of the JWT payload made with a chosen key to allow verification of the JWT content.",
      "args":[{
        "name":"A key string",
        "type":"string",
        "required": true
      },{
        "name" : "Name of a JWT standard hash algorithm from among HS256, HS384, or HS512",
        "type":"string",
        "required": true
      },{
        "name" : "The payload, typically a JSON object with name-value pairs",
        "type":"string",
        "required": true
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "GetJWTByKeyName",
      "link": "https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/GetJWT.html",
      "description": "Returns a JSON web token (JWT) including a hash of the JWT payload made with key name that matches a CustomerKey value of a MID to allow verification of the JWT content.",
      "args":[{
        "name":"A key name matching the CustomerKey value of the MID",
        "type":"string",
        "required": true
      },{
        "name" : "Name of a JWT standard hash algorithm from among HS256, HS384, or HS512",
        "type":"string",
        "required": true
      },{
        "name" : "The payload, typically a JSON object with name-value pairs",
        "type":"string",
        "required": true
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "RegExMatch",
      "link": "https://ampscript.guide/regexmatch",
      "description": "This function searches for and returns a string for the first occurrence of the matching regular expression pattern.",
      "args":[{
        "name":"String to search for a match",
        "type":"string",
        "required": true
      },{
        "name" : "Regular expression to use in the search",
        "type":"string",
        "required": true
      },{
        "name" : "Name or ordinal of the matching group to return",
        "type":"string",
        "required": true
      },{
        "name" : "Additional repeating string parameter options",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    },
    {
      "functionName": "UpsertContact",
      "link": "https://ampscript.guide/upsertcontact",
      "description": "This function ‘upserts’ a Contact in Marketing Cloud. If the Contact does not already exist, then a new record is created. If the Contact exists, then the record is updated. The function will output 1 if an error occurred when upserting the Contact and 0 if the upsert was successful.",
      "args":[{
        "name":"The Contact channel. Only mobile is supported at this time.",
        "type":"string",
        "required": true
      },{
        "name" : "Attribute to match the Contact. Only phone is supported at this time.",
        "type":"string",
        "required": true
      },{
        "name" : "Phone number with country area code",
        "type":"number",
        "required": true
      },{
        "name" : "Attribute name to upsert",
        "type":"string",
        "required": true
      },{
        "name" : "Attribute value to upsert",
        "type":"string",
        "required": true
      },{
        "name" : "Additional attribute name to upsert",
        "type":"string",
        "required": false
      },{
        "name" : "Additional attribute value to upsert",
        "type":"string",
        "required": false
      }],
      "unevenArgsRequired": true
    }
    ]