export default [{
    "functionName": "CreateMSCRMRecord",
    "link": "https://ampscript.guide/createmscrmrecord",
    "description": "This function creates a record in the specified Microsoft Dynamics CRM entity. It returns the GUID of the record created.",
    "args":[{
      "name":"Name of the MSCRM entity",
      "type":"string",
      "required": true
    },{
      "name" : "Number of attribute and value pairs in the preceding arguments",
      "type":"string",
      "required": true
    },{
      "name" : "First attribute name for new record",
      "type":"string",
      "required": true
    },{
      "name" : "First attribute value for new record",
      "type":"string",
      "required": true
    },{
      "name" : "Additional attribute name for new record",
      "type":"string",
      "required": false
    },{
      "name" : "Additional attribute value for new record",
      "type":"string",
      "required": false
    }]
  },
  {
    "functionName": "CreateSalesforceObject",
    "link": "https://ampscript.guide/createsalesforceobject",
    "description": "This function creates a new record in a Salesforce object. The Salesforce 18-digit object identifier is returned by the function.",
    "args":[{
      "name":"API name of the Salesforce object",
      "type":"string",
      "required": true
    },{
      "name" : "Number of fields inserted when creating the record",
      "type":"number",
      "required": true
    },{
      "name" : "API field name",
      "type":"string",
      "required": true
    },{
      "name" : "Value to insert",
      "type":"string",
      "required": true
    },{
      "name" : "Additional API field name",
      "type":"string",
      "required": false
    },{
      "name" : "Additional value to insert",
      "type":"string",
      "required": false
    }]
  }
  ]