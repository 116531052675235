import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomeScreenContainer from "../HomeScreen/HomeScreenContainer";

const AppRouter = () => {
    return (
        <Router>
            <Route
            path="/"
            exact
            component={HomeScreenContainer}
            />
        </Router>
    )
}

export default AppRouter;