
// Converted automatically using ./tools/themeFromVsCode
var theme = {
  plain: {
    color: "#9CDCFE",
    backgroundColor: "#1E1E1E"
  },
  styles: [{
    types: ["prolog"],
    style: {
      color: "rgb(0, 0, 128)"
    }
  }, {
    types: ["amp-keyword"],
    style: {
      color: "rgb(209,105,105)"
    }
  }, {
    types: ["nonamp-keyword"],
    style: {
      color: "#9CDCFE"
    }
  }, {
    types: ["other-keyword"],
    style: {
      color: "rgb(60, 143, 193)"
    }
  }, {
    types: ["loop-keyword"],
    style: {
      color: "rgb(129, 216, 208)"
    }
  }, {
    types: ["conditional-keyword"],
    style: {
      color: "rgb(194, 163, 201)"
    }
  }, {
    types: ["amp-variables"],
    style: {
      color: "rgb(156,220,254)"
    }
  },
  {
    types: ["comment"],
    style: {
      color: "rgb(104,191,104)"
    }
  },
  {
    types: ["builtin", "changed", "keyword"],
    style: {
      color: "rgb(60, 143, 193)"
    }
  }, {
    types: ["number", "inserted"],
    style: {
      color: "rgb(181, 206, 168)"
    }
  }, {
    types: ["constant"],
    style: {
      color: "rgb(60, 143, 193)"
    }
  }, {
    types: ["attr-name", "variable"],
    style: {
      color: "rgb(156, 220, 254)"
    }
  }, {
    types: ["deleted", "string", "attr-value"],
    style: {
      color: "rgb(206, 145, 120)"
    }
  }, {
    types: ["selector"],
    style: {
      color: "rgb(215, 186, 125)"
    }
  }, {
    // Fix tag color
    types: ["tag"],
    style: {
      color: "rgb(78, 201, 176)"
    }
  }, {
    // Fix tag color for HTML
    types: ["tag"],
    languages: ["markup"],
    style: {
      color: "rgb(86, 156, 214)"
    }
  }, {
    types: ["punctuation", "operator"],
    style: {
      color: "rgb(212, 212, 212)"
    }
  }, {
    // Fix punctuation color for HTML
    types: ["punctuation"],
    languages: ["markup"],
    style: {
      color: "#808080"
    }
  }, {
    types: ["function"],
    style: {
      color: "rgb(220, 220, 170)"
    }
  }, {
    types: ["class-name"],
    style: {
      color: "rgb(60, 143, 193)"
    }
  }, {
    types: ["char"],
    style: {
      color: "rgb(209, 105, 105)"
    }
  }]
};

module.exports = theme;